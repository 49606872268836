<template>
  <div class="container mt-4">
    <div class="row">
      <div class="col center-col"></div>
      <div class="col center-col">
        <div class="dropdown p-4" id="card">
          <div class="row">
            <div class="col"></div>
            <div class="col center-col">
              <img src="../assets/img/logo_app.jpg" />
            </div>
            <div class="col"></div>
          </div>
          <form class="px-4 py-3" @submit.prevent="restore">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mCompany.users.password`) + " *"
              }}</label>
              <input
                v-if="visPas"
                type="password"
                class="form-control"
                v-model.trim="password"
                :placeholder="$t(`mCompany.users.password`) + '*'"
                maxlength="20"
              />
              <input
                v-else
                type="text"
                class="form-control"
                v-model.trim="password"
                :placeholder="$t(`mCompany.users.password`) + '*'"
                maxlength="20"
              />
              <i
                v-if="!visPas"
                @click="visPas = true"
                class="fas fa-eye-slash field-icon"
              ></i>
              <i
                v-else
                @click="visPas = false"
                class="fas fa-eye field-icon"
              ></i>
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`configuration.repeatPassword`) + " *"
              }}</label>
              <input
                v-if="visPas2"
                type="password"
                class="form-control"
                v-model.trim="rPassword"
                :placeholder="$t(`configuration.repeatPassword`) + '*'"
                maxlength="20"
              />
              <input
                v-else
                type="text"
                class="form-control"
                v-model.trim="rPassword"
                :placeholder="$t(`configuration.repeatPassword`) + '*'"
                maxlength="20"
              />
              <i
                v-if="!visPas2"
                @click="visPas2 = true"
                class="fas fa-eye-slash field-icon"
              ></i>
              <i
                v-else
                @click="visPas2 = false"
                class="fas fa-eye field-icon"
              ></i>
            </div>
            <div class="mb-3">
              <span
                v-if="formError"
                class="text-danger"
                style="float: right; font-size: 12px; margin-bottom: 18pt;"
                >{{ messageError }}</span
              >
            </div>
            <button type="submit" class="btn button" @click="sendPassword">
              {{ $t("configuration.change") }}
            </button>
          </form>
        </div>
      </div>
      <div class="col center-col"></div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="resultadoModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("configuration.changePassword") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" v-if="result === '0' || result === 0">
          {{ $t("errors.0") }}
        </div>
        <div class="modal-body" v-else>
          {{ $t("errors.6") }}
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-response"
            data-bs-dismiss="modal"
            @click="close"
          >
            {{ $t("actions.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      password: "",
      rPassword: "",
      visPas: true,
      visPas2: true,
      formError: false,
      messageError: "",
      code: this.$route.params.code,
      idemp: this.$route.params.idemp,
      result: "",
    };
  },
  created() {
    this.littleDrop();
    this.changeBreadCrumb(30);
  },
  computed: {
    ...mapState(["servidor"])
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "littleDrop"]),
    async sendPassword() {
      if (this.validatePassword()) {
        this.formError = false;
        this.messageError = '';
        const data = {
          usu_cambio: this.code,
          usu_clave: this.password,
        };
        const res = await axios.post(
          `${this.servidor}api/ident_usuario.asp?ac=clave`,
          data
        );
        this.result = res.data.RESULT;
        $("#resultadoModal").modal("show");
      }
    },
    validatePassword() {
      if (this.password === this.rPassword) {
        if (this.password.length >= 8) {
          let mayuscula = false;
          let minuscula = false;
          let numero = false;
          let caracter_raro = false;

          for (var i = 0; i < this.password.length; i++) {
            if (
              this.password.charCodeAt(i) >= 65 &&
              this.password.charCodeAt(i) <= 90
            ) {
              mayuscula = true;
            } else if (
              this.password.charCodeAt(i) >= 97 &&
              this.password.charCodeAt(i) <= 122
            ) {
              minuscula = true;
            } else if (
              this.password.charCodeAt(i) >= 48 &&
              this.password.charCodeAt(i) <= 57
            ) {
              numero = true;
            } else {
              caracter_raro = true;
            }
          }
          if (
            mayuscula == true &&
            minuscula == true &&
            caracter_raro == true &&
            numero == true
          ) {
            return true;
          }
        }
        this.messageError = this.$t("configuration.messageError2");
        this.formError = true;
        return false;
      } else {
        this.formError = true;
        this.messageError = this.$t("configuration.messageError");
      }
    },
    close() {
      if (this.result === '0') {
        this.$router.push(`/${this.idemp}/user/login`);
      }
    }
  },
};
</script>


<style scoped>
#card {
  width: 500px;
  border-radius: 30px;
  background-color: white;
  margin-top: 185px;
}

.field-icon {
  float: right;
  margin-top: -69px;
  position: relative;
  z-index: 2;
  left: -11px;
  cursor: pointer;
}

.button {
  padding: 10px;
  width: 100%;
  border-radius: 50px;
  color: #fff;
  background-color: #e99417;
}

.label {
  font-size: 10pt;
  color: #a4a9a8;
  margin-top: 19pt;
  float: right;
}

input {
  height: 35pt;
  text-indent: 50px;
}

.center-col {
  display: flex;
  justify-content: center;
}

.img-fondo {
  margin-top: 72pt;
  width: 100%;
  height: 75%;
}

.btn-response {
  background-color: #a4a9a8;
  border-radius: 20pt;
  color: white;
  width: 20%;
}
</style>