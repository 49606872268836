<template>
  <!-- <div class="locale-changer" style="float: right; margin-right: 10px"> -->
    <li v-if="!device">
      <span class="dropdown" @click="changeLang('en')"
        ><img src="../assets/img/en_lang.png" style="width: 25px" /> EN</span
      >
    </li>
    <li v-if="!device">
      <span class="dropdown" @click="changeLang('es')"
        ><img src="../assets/img/es_lang.png" style="width: 25px" /> ES</span
      >
    </li>

    <!-- <span @click="changeLang('es')"
      ><img src="../assets/img/es_lang.png" style="width: 25px"
    /></span>
    <span @click="changeLang('en')"
      ><img src="../assets/img/en_lang.png" style="width: 25px"
    /></span> -->
  <!-- </div> -->
</template>

<script>
import { VueCookieNext } from "vue-cookie-next";

export default {
  props: ["device"],
  name: "locale-changer",
  data() {
    return {
      langs: [{ language: "EN" }, { language: "ES" }],
    };
  },
  mounted: function () {
    if (!VueCookieNext.getCookie("lang")) {
      this.changeLang('es');
    }
    this.$i18n.locale = VueCookieNext.getCookie("lang");
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang;
      VueCookieNext.setCookie("lang", lang, { expire: Infinity });
    },
  },
};
</script>

<style scoped>
.dropdown {
  font-weight: bold;
  padding: 10pt;
  font-size: 10pt;
}
li {
  cursor: pointer;
}
</style>
