import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
    state: {
        logo: "",
        id_emp: "",
        id_usu: "",
        usu_rol: 0,
        navbar: true,
        allDrop: false,
        breadCrumb: 0,
        reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        bigmenu: false,
        colorCompany: "#e99417",
        activeLogo: true,
        titleCourse: "",
        device: false,
        comunica: false,
        professorId: null,
        servidor: "https://formacion.consultoriacomunica.com/"
    },
    mutations: {
        deactiveNavbar(state) {
            state.navbar = false;
        },
        activeNavbar(state) {
            state.navbar = true;
        },
        littleDrop(state) {
            state.allDrop = true;
        },
        bigDrop(state) {
            state.allDrop = false;
        },
        changeBreadCrumb(state, payload) {
            state.breadCrumb = payload;
        },
        changeIdEmp(state, payload) {
            state.id_emp = payload;
        },
        changeLogo(state, payload) {
            console.log("STORELGO", payload)
            state.logo = payload;
        },
        changeIdUsu(state, payload) {
            state.id_usu = payload;
        },
        changeTipoUsu(state, payload) {
            state.usu_rol = payload;
        },
        changeBigmenu(state, payload) {
            state.bigmenu = payload;
        },
        changeColorCompanyOrange(state) {
            state.colorCompany = "#e99417";
        },
        changeColorCompanyGreen(state) {
            state.colorCompany = "#6ed966";
        },
        changeColorCompanyRed(state) {
            state.colorCompany = "#d53009";
        },
        changeColorCompanyBlue(state) {
            state.colorCompany = "#82dcce";
        },
        changeTitleCourse(state, payload) {
            state.titleCourse = payload;
        },
        changeDevice(state, payload) {
            state.device = payload;
        },
        changeComunica(state, payload) {
            state.comunica = payload;
        },
        changeServidor(state, payload) {
            state.servidor = payload;
        },
        changeProfessorId(state, payload) {
            state.professorId = payload;
        },
        writeConsole(state, payload) {
            console.log(payload)
        }
    },
    actions: {
        async superuserCrud(data, table, action) {
            try {
                const res = await axios.post(
                    `${this.servidor}api/op.asp?tp=${table}&ac=${action}`,
                    data
                );
                console.log(res);
                $("#responseModal").modal("show");
                return res.data.RESULT;
            } catch (error) {
                console.error(error);
                $("#responseModal").modal("show");
                return "1";
            }
        },
        isEmailValid(email) {
            return email == "" ?
                "" :
                this.reg.test(email) ?
                true :
                false;
        }
    },
    getters: {

    },
    modules: {}
})