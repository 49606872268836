{
    "actions": {
        "close": "Cerrar",
        "send": "Enviar",
        "toattach": "Documentación/Evidencia",
        "save": "Guardar",
        "clean": "Limpiar",
        "singin": "Iniciar sesión",
        "restore": "Recuperar",
        "return": "Volver",
        "confirm": "Aceptar",
        "cancel": "Cancelar",
        "access": "Acceder",
        "create": "Crear",
        "signout": "Cerrar sesión",
        "accBlock": "Acceder al contenido",
        "imgText": "Arrastre y suelte el archivo o pulse aquí",
        "btnProfesor": "Contacto profesor",
        "sendMessage": "Nuevo mensaje",
        "btnCertificate": "Descargar certificado"
    },
    "login": {
        "user": "Nombre de usuario",
        "password": "Contraseña",
        "forgot": "¿No recuerda su contraseña?",
        "title": "Identificación de super usuario",
        "titleEmp": "Identificación de empresa",
        "titleUser": "Identificación de usuario",
        "titleUserMobile": "Identificación",
        "restore": "Recuperar",
        "titleRestore": "Recuperar contraseña",
        "finalMesssage": "Se ha recuperado su contraseña.",
        "restoreFail": "Ha ocurrido un error, intentelo de nuevo.",
        "email": "Correo electrónico",
        "errorUsu": "Nombre de usuario o contraseña incorrectos",
        "errorBlock": "El usuario está bloqueado, póngase en contacto con un administrador para desbloquearlo",
        "restoreFailEmail": "El formato del email no es válido"
    },
    "error": {
        "name": "El nombre es un campo obligatorio",
        "user": "El usuario es un campo obligatorio"
    },
    "menu": {
        "mcompany": "CREAR EMPRESA",
        "msuperuser": "CREAR SUPERUSUARIO",
        "countryMain": "MANTENIMIENTO DE PAÍSES",
        "mainLanguages": "MANTENIMIENTO DE IDIOMAS",
        "mainCourses": "MANTENIMIENTO DE CURSOS",
        "mainExams": "MANTENIMIENTO DE EXÁMENES",
        "users": "MANTENIMIENTO DE USUARIOS",
        "mprofesor": "MANTENIMIENTO DE PROFESORES",
        "mydata": "MIS DATOS",
        "statistics": "ESTADÍSTICAS",
        "chistory": "HISTORIAL DE MIS CURSOS",
        "chistorymobile": "HISTORIAL",
        "courses": "MIS CURSOS",
        "course": "CURSO ACTUAL",
        "mylinks": "MIS ENLACES",
        "notice": "MIS AVISOS",
        "noticemin": "Mis avisos",
        "searcher": "BUSCADOR",
        "config": "CONFIGURACIÓN",
        "changep": "CAMBIO DE CONTRASEÑA",
        "compcourses": "CURSOS",
        "vercurso": "VER CURSO",
        "vertemas": "VER TEMAS",
        "verbloques": "VER CONTENIDOS",
        "vermaterial": "VER MATERIAL",
        "questions": "VER PREGUNTAS",
        "editar": "EDITAR",
        "borrar": "BORRAR",
        "categories": "VER CATEGORIAS",
        "seo": "VER SEO",
        "contact": "VER CONTACTO",
        "vprofesor": "VER PROFESORES",
        "hecho": "REALIZADO",
        "seecourses": "VER CURSOS",
        "messages": "VER MENSAJES",
        "alert": "ENVIAR ALERTA",
        "deasigncourse": "QUITAR DEL CURSO",
        "asigncourse": "ASIGNAR CURSO",
        "rate": "VALORAR",
        "homework": "TRABAJOS DEL ALUMNO",
        "works": "VER TRABAJOS"
    },
    "maintenance": {
        "actions": {
            "add": "Agregar",
            "cancel": "Cancelar",
            "confirm": "Aceptar"
        },
        "exams": {
            "question": "Preguntas",
            "title": "MANTENIMIENTO DE EXÁMENES",
            "deleteTitle": "Borrando examen {name}",
            "deleteMessage": "¿Está seguro que desea eliminar el examen?",
            "deleteConfirmTitle": "El examen ha sido eliminado",
            "deleteConfirm": "El examen ha sido eliminado",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando examen {name}",
            "editMessage": "El examen ha sido editado",
            "addTitle": "Agregando nuevo examen"
        },
        "questions": {
            "question": "Pregunta",
            "answer": "Respuesta",
            "canswer": "Respuesta Correcta",
            "title": "MANTENIMIENTO DE PREGUNTAS",
            "deleteTitle": "Borrando pregunta {name}",
            "deleteMessage": "¿Está seguro que desea eliminar la pregunta?",
            "deleteConfirmTitle": "La pregunta ha sido eliminada",
            "deleteConfirm": "La pregunta ha sido eliminada",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando pregunta {name}",
            "editMessage": "La pregunta ha sido editado",
            "addTitle": "Agregando nueva pregunta"
        },
        "courses": {
            "title": "MANTENIMIENTO DE CURSOS",
            "themes": "Temas",
            "students": "Alumnos",
            "deleteTitle": "Borrando curso {name}",
            "deleteMessage": "¿Está seguro que desea eliminar el curso?",
            "deleteConfirmTitle": "El curso ha sido eliminado",
            "deleteConfirm": "El curso ha sido eliminado",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando curso {name}",
            "editMessage": "El curso ha sido editado",
            "addTitle": "Agregando nuevo curso"
        },
        "themes": {
            "title": "MANTENIMIENTO DE TEMAS",
            "blocks": "Contenidos",
            "deleteTitle": "Borrando tema {name}",
            "deleteMessage": "¿Está seguro que desea eliminar el tema?",
            "deleteConfirmTitle": "El tema ha sido eliminado",
            "deleteConfirm": "El tema ha sido eliminado",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando tema {name}",
            "editMessage": "El tema ha sido editado",
            "addTitle": "Agregando nuevo tema"
        },
        "materiales": {
            "title": "MANTENIMIENTO DE MATERIALES",
            "deleteTitle": "Borrando material",
            "deleteMessage": "¿Está seguro que desea eliminar el material?",
            "deleteConfirmTitle": "El material ha sido eliminado",
            "deleteConfirm": "El material ha sido eliminado",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando material",
            "editMessage": "El material ha sido editado",
            "addTitle": "Agregando nuevo material"
        },
        "blocks": {
            "title": "MANTENIMIENTO DE CONTENIDOS",
            "deleteTitle": "Borrando contenido {name}",
            "deleteMessage": "¿Está seguro que desea eliminar el contenido?",
            "deleteConfirmTitle": "El contenido ha sido eliminado",
            "deleteConfirm": "El contenido ha sido eliminado",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando contenido {name}",
            "editMessage": "El contenido ha sido editado",
            "addTitle": "Agregando nuevo contenido",
            "typeexam": "Tipo de examen",
            "ruteYoutube": "Dirección del video",
            "workStud": "Trabajo del alumno"
        },

        "languages": {
            "title": "MANTENIMIENTO DE IDIOMAS",
            "name": "Nombre",
            "key": "Código",
            "deleteTitle": "Borrando idioma {name}",
            "deleteMessage": "¿Está seguro que desea eliminar el idioma?",
            "deleteConfirmTitle": "El idioma ha sido eliminado",
            "deleteConfirm": "El idioma ha sido eliminado",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando idioma {name}",
            "editMessage": "El idioma ha sido editado",
            "addTitle": "Agregando nuevo idioma"
        },
        "countries": {
            "title": "MANTENIMIENTO DE PAÍSES",
            "name": "Nombre",
            "id": "Código",
            "deleteTitle": "Borrando país {name}",
            "deleteMessage": "¿Está seguro que desea eliminar el país?",
            "deleteConfirmTitle": "El país ha sido eliminado",
            "deleteConfirm": "El país ha sido eliminado",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando país {name}",
            "editMessage": "El país ha sido editado",
            "addTitle": "Agregando nuevo país"
        },
        "categories": {
            "title": "MANTENIMIENTO DE CATEGORÍAS",
            "nameEsp": "Nombre Español",
            "nameEng": "Nombre Inglés",
            "color": "Color",
            "deleteTitle": "Borrando categoria {name}",
            "deleteMessage": "¿Está seguro que desea eliminar la categoria?",
            "deleteConfirmTitle": "La categoria ha sido eliminada",
            "deleteConfirm": "La categoria ha sido eliminada",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando categoria {name}",
            "editMessage": "La categoria ha sido editada",
            "addTitle": "Agregando nueva categoria"
        },
        "seos": {
            "title": "MANTENIMIENTO DE SEO",
            "titulo": "Título",
            "url": "URL",
            "description": "Descripción",
            "keywords": "Palabras clave",
            "text": "Texto",
            "lang": "Idioma",
            "deleteTitle": "Borrando seo {name}",
            "deleteMessage": "¿Está seguro que desea eliminar el seo?",
            "deleteConfirmTitle": "El seo ha sido eliminado",
            "deleteConfirm": "El seo ha sido eliminado",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando seo {name}",
            "editMessage": "El seo ha sido editado",
            "addTitle": "Agregando nuevo seo",
            "canonical": "Canónica"
        },
        "natures": {
            "title": "MANTENIMIENTO DE NATURALEZAS",
            "name": "Nombre",
            "deleteTitle": "Borrando naturaleza {name}",
            "deleteMessage": "¿Está seguro que desea eliminar la naturaleza?",
            "deleteConfirmTitle": "La naturaleza ha sido eliminada",
            "deleteConfirm": "La naturaleza ha sido eliminada",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando naturaleza {name}",
            "editMessage": "La naturaleza ha sido editada",
            "addTitle": "Agregando nueva naturaleza"
        },
        "relations": {
            "title": "MANTENIMIENTO DE RELACIONES",
            "name": "Nombre",
            "deleteTitle": "Borrando relación {name}",
            "deleteMessage": "¿Está seguro que desea eliminar la relación?",
            "deleteConfirmTitle": "La relación ha sido eliminada",
            "deleteConfirm": "La relación ha sido eliminada",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando relación {name}",
            "editMessage": "La relación ha sido editada",
            "addTitle": "Agregando nueva relación"
        },
        "superusers": {
            "title": "Super usuario",
            "name": "Nombre",
            "id": "Código",
            "email": "Correo electrónico",
            "password": "Contraseña",
            "state": "Estado",
            "deleteTitle": "Borrando super usuario {name}",
            "deleteMessage": "¿Está seguro que desea eliminar el super usuario?",
            "deleteConfirmTitle": "El super usuario ha sido eliminado",
            "deleteConfirm": "El super usuario ha sido eliminado",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando super usuario {name}",
            "editMessage": "El super usuario ha sido editado",
            "addTitle": "Agregando nuevo super usuario",
            "lock": "Desbloquear"
        },
        "companies": {
            "title": "Empresas",
            "name": "Nombre",
            "cif": "CIF",
            "email": "Correo electrónico",
            "password": "Contraseña",
            "direction": "Dirección",
            "population": "Población",
            "cp": "CP",
            "user": "Usuario",
            "province": "Provincia",
            "group": "¿A qué grupo pertenece?",
            "logo": "Logotipo",
            "designType": "Tipo de diseño",
            "companyType": "Tipo de empresa",
            "lang": "Idioma",
            "state": "Estado",
            "single": "Única",
            "iGroup": "¿Es grupo?",
            "belongs": "Pertenece",
            "country": "País",
            "isGroup": "¿Pertenece a un grupo?",
            "is-group": "Grupo?",
            "deleteTitle": "Borrando empresa {name}",
            "deleteMessage": "¿Está seguro que desea eliminar la empresa?",
            "deleteConfirmTitle": "La empresa ha sido eliminado",
            "deleteConfirm": "La empresa ha sido eliminado",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando empresa {name}",
            "editMessage": "La empresa ha sido editado",
            "addTitle": "Agregando nueva empresa",
            "imgText": "Arrastre y suelte la imagen o pulse aquí",
            "unlock": "Desbloquear",
            "lock": "Bloquear",
            "typeRed": "Tipo rojo",
            "typeBlue": "Tipo azul",
            "typeGreen": "Tipo verde",
            "typeOrange": "Tipo naranja"
        },
        "fonts": {
            "title": "MANTENIMIENTO DE FUENTES",
            "name": "Nombre",
            "deleteTitle": "Borrando relación {name}",
            "deleteMessage": "¿Está seguro que desea eliminar la fuente?",
            "deleteConfirmTitle": "La fuente ha sido eliminada",
            "deleteConfirm": "La fuente ha sido eliminada",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando fuente {name}",
            "editMessage": "La fuente ha sido editada",
            "addTitle": "Agregando nueva fuente"
        }
    },
    "mCompany": {
        "users": {
            "title": "MANTENIMIENTO DE USUARIOS",
            "name": "Nombre",
            "password": "Contraseña",
            "email": "Correo electrónico",
            "dni": "DNI",
            "pertenece": "Pertenece a",
            "user": "Usuario",
            "user1": "Usuario",
            "deleteTitle": "Borrando Usuario {name}",
            "deleteMessage": "¿Está seguro que desea eliminar el usuario?",
            "deleteConfirmTitle": "El usuario ha sido eliminado",
            "deleteConfirm": "El usuario ha sido eliminado",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando usuario {name}",
            "editMessage": "El usuario ha sido editado",
            "addTitle": "Agregando nuevo usuario",
            "state": "Estado",
            "signature": "¿Tiene firma digital?",
            "courses": "Cursos asignados",
            "listcourses": "Listado de cursos",
            "assigned": "Cursos asignados",
            "noAssigned": "Cursos no asignados",
            "incourse": "En curso",
            "finished": "Finalizado",
            "theme": "Tema Actual",
            "messages": "Mensajes con el alumno",
            "time": "Tiempo total",
            "report": "INFORMES",
            "listMessages": "Mensajes",
            "worksFromStudent": "Trabajos del alumno",
            "noworks": "El alumno no ha subido trabajos"
        },
        "departments": {
            "title": "MANTENIMIENTO DE DEPARTAMENTOS",
            "name": "Nombre",
            "deleteTitle": "Borrando departamento {name}",
            "deleteMessage": "¿Está seguro que desea eliminar el departamento?",
            "deleteConfirmTitle": "El departamento ha sido eliminado",
            "deleteConfirm": "El departamento ha sido eliminado",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando departamento {name}",
            "editMessage": "El departamento ha sido editado",
            "addTitle": "Agregando nuevo departamento"
        },
        "headquarters": {
            "title": "MANTENIMIENTO DE SEDES",
            "name": "Nombre",
            "deleteTitle": "Borrando sede {name}",
            "deleteMessage": "¿Está seguro que desea eliminar la sede?",
            "deleteConfirmTitle": "La sede ha sido eliminado",
            "deleteConfirm": "La sede ha sido eliminado",
            "deleteError": "Ha ocurrido un error mientras borraba",
            "editTitle": "Editando sede {name}",
            "editMessage": "La sede ha sido editado",
            "addTitle": "Agregando nuevo sede"
        },
        "mylink": {
            "title": "Mis links",
            "linkForms": "Enlaces a formularios",
            "linkUser": "Enlaces a inicio sesión",
            "linkWhistleblower": "Enlace estado de tu consulta"
        },
        "statistic": {
            "title": "Mis estadísticas"
        }
    },
    "mUser": {
        "courses": {
            "title": "Mis cursos",
            "titleProfesor": "Mis mensajes",
            "titleProfesor2": "Alumnos",
            "titleProfesor3": "Curso",
            "titleProfesor4": "Trabajos",
            "name": "Título",
            "duration": "Duración",
            "lang": "Idioma",
            "description": "Descripción",
            "descriptionL": "Descripción larga",
            "knowledge": "Conocimientos previos",
            "theme": "Temas",
            "block": "Contenido",
            "calif": "Calificación",
            "iWeb": "En Web",
            "category": "Categoría",
            "price": "Precio",
            "advice": "Aviso",
            "iniDate": "Fecha inicio",
            "finDate": "Fecha fin",
            "amount": "Cantidad de alumnos",
            "envioAviso": "Volver a enviar los datos de acceso",
            "outOfTime": "No disponible: Fuera de plazo",
            "ncourse": "Nombre del curso",
            "nblock": "Nombre del contenido",
            "web": "Web",
            "notaDelAlumno": "Nota del alumno"
        },
        "course": {
            "title": "Curso actual",
            "name": "Título",
            "duration": "Duración",
            "lang": "Idioma",
            "description": "Descripción",
            "block": "",
            "profesorTitle": "Correo para el profesor",
            "material": "MATERIAL DEL CURSO",
            "mensaje": "Mensaje",
            "workStudent": "El trabajo ya se ha subido, solo podrá descargarlo.",
            "problemProf": "Si necesita más información pongase en contacto con el profesor.",
            "workProf": "Trabajo subido por el alumno",
            "valCourse": "Valorar curso"
        },
        "history": {
            "title": "Historial de cursos",
            "name": "Título",
            "duration": "Duración",
            "lang": "Idioma",
            "description": "Descripción"
        }
    },
    "phases": {
        "actions": {
            "close": "Cerrar Caso",
            "save": "Guardar",
            "addFile": "Adjuntar nuevo archivo",
            "seeFiles": "Ver historial de archivos",
            "seePDF": "Ver historial PDF"
        },
        "id": "ID",
        "date": "Fecha",
        "type": "Tipo",
        "state": "Estado",
        "lastUser": "Último usuario",
        "commentary": "Comentario"
    },
    "errors": {
        "0": "Datos almacenados correctamente",
        "1": "Se ha producido un error.",
        "1.1": "Revise los datos del formulario",
        "2": "Ya existe el registro",
        "3": "No existen registros",
        "4": "Usuario desbloqueado",
        "8": "Usuario bloqueado",
        "5": "Empresa desbloqueada",
        "6": "Ha ocurrido un error. Inténtelo de nuevo más tarde.",
        "7": "Debe seleccionar un certificado para poder cerrar el caso",
        "title": "Aviso",
        "fieldOb": "Debe rellenar todos los campos obligatorios (*)",
        "comment": "Los campos con * son obligatorios",
        "errorEmail": "El formato del email no es válido",
        "prof1": "Ha ocurrido un error, intentelo de nuevo más tarde",
        "prof0": "Solicitud enviada correctamente"
    },
    "configuration": {
        "email": "Correo electrónico",
        "user": "Usuario",
        "password": "Contraseña",
        "server": "Servidor",
        "port": "Puerto",
        "security": "Seguridad",
        "nosec": "Sin seguridad",
        "footer": "Pie legal RGPD",
        "save": "Guardar configuración",
        "repeatPassword": "Repita la contraseña",
        "messageError": "Las contraseñas son diferentes",
        "messageError2": "La contraseña debe tener al menos 8 caractéres y contener una mayúsucula, una minúscula, un número y un símbolo.",
        "change": "Grabar",
        "changePassword": "Cambio de contraseña",
        "newPassword": "Nueva contraseña"
    },
    "profesor": {
        "deleteTitle": "Borrando profesor {name}",
        "deleteMessage": "¿Está seguro que desea eliminar el profesor?",
        "deleteConfirmTitle": "El profesor ha sido eliminada",
        "deleteConfirm": "El profesor ha sido eliminado",
        "deleteError": "Ha ocurrido un error mientras borraba",
        "editTitle": "Editando profesor {name}",
        "editMessage": "El profesor ha sido editado",
        "addTitle": "Agregando nuevo profesor",
        "login": "Identificación de profesor",
        "inicio": "PROFESOR",
        "titleAdd": "Enviar mensaje...",
        "comisiones": "Cartera",
        "exams": "Exámenes",
        "courses": "Mis cursos:",
        "solicitud": "SOLICITAR PUBLICACIÓN",
        "name": "Nombre",
        "email": "Correo electrónico",
        "password": "Clave",
        "nac_date": "Fecha de nacimiento",
        "prefix": "Prefijo",
        "phone": "Teléfono",
        "iban": "IBAN",
        "lang": "Idioma",
        "dni": "DNI",
        "revWork": "Detalle del trabajo",
        "note": "Nota",
        "wallet": {
            "detailC": "Detalle de los cursos",
            "course": "Curso",
            "price": "Precio",
            "students": "Alumnos finalizados",
            "total": "Total",
            "refunds": "Listado de reembolsos",
            "date": "Fecha",
            "amount": "Cantidad",
            "state": "Estado",
            "totalBalance": "Saldo total",
            "reqRefund": "Solicitar reembolso"

        }
    },
    "languages": {
        "title": "Idioma: ",
        "es": "Español",
        "en": "Inglés"
    },
    "contact": {
        "name": "Nombre",
        "email": "Correo electrónico",
        "prefix": "Prefijo",
        "phone": "Teléfono",
        "message": "Mensaje",
        "lang": "Idioma",
        "ip": "Ip",
        "birthday": "Fecha de cumpleaños",
        "dni": "DNI",
        "title": "Título",
        "type": "Tipo",
        "date": "Fecha",
        "state": "Estado",
        "navbar": "MANTENIMIENTO DE CONTACTOS",
        "viewTitle": "Detalle de contacto",
        "changeState": "Finalizado"
    },
    "valor": {
        "puntos": "Valoración",
        "comentario": "Comentario",
        "title": "Valoración del curso"
    }
}