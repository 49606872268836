<template>
  <!-- As a heading -->
  <nav
    v-if="navbar && !device"
    class="navbar navbar-light"
    style="
      background-color: white;
      position: fixed;
      width: 100%;
      top: 0;
      z-index: 100;
      min-height: 65pt;
    "
  >
    <div class="container-fluid">
      <div class="comp-navbar">
        <img
          v-if="
            (breadCrumb === 32 ||
            breadCrumb === 13 ||
            breadCrumb === 14 ||
            breadCrumb === 20) && !comunica
          "
          class="img-logo-main"
          :src="logo"
        />
        <img v-else-if="comunica" class="img-logo-main" src="../assets/img/logo-ad.png" />
        <img v-else class="img-logo-main" src="../assets/img/logo_app.jpg" />
        <span style="font-size: 22pt">          
          <!-- Super user -->
          <span v-if="breadCrumb === 1" style="font-weight: bold"><i class="fas fa-building icons"></i> {{
            $t("maintenance.companies.title")
          }}</span>
          <span v-if="breadCrumb === 2" style="font-weight: bold"><i class="fas fa-globe-europe icons"></i>{{
            $t("maintenance.countries.title")
          }}</span>
          <span v-if="breadCrumb === 3" style="font-weight: bold"><i class="fas fa-language icons"></i>{{
            $t("maintenance.languages.title")
          }}</span>
          <span v-if="breadCrumb === 15" style="font-weight: bold"><i class="fas fa-graduation-cap icons"></i>{{
            $t("maintenance.courses.title")
          }}</span>
          <span v-if="breadCrumb === 16" style="font-weight: bold"><i class="fas fa-list icons"></i>{{
            $t("maintenance.themes.title")
          }}</span>
          <span v-if="breadCrumb === 17" style="font-weight: bold"><i class="fas fa-th icons"></i>{{
            $t("maintenance.blocks.title")
          }}</span>
          <span v-if="breadCrumb === 18" style="font-weight: bold"><i class="fas fa-book-reader icons"></i>{{
            $t("maintenance.exams.title")
          }}</span>
          <span v-if="breadCrumb === 19" style="font-weight: bold"><i class="fas fa-clipboard-list icons"></i>{{
            $t("maintenance.questions.title")
          }}</span>
          <span v-if="breadCrumb === 4" style="font-weight: bold"><i class="fas fa-user-plus icons"></i>{{
            $t("maintenance.superusers.title")
          }}</span>
          <span v-if="breadCrumb === 5" style="font-weight: bold">{{
            $t("login.title")
          }}</span>
          <span v-if="breadCrumb === 6" style="font-weight: bold">{{
            $t("mCompany.statistic.title")
          }}</span>
          <span v-if="breadCrumb === 21" style="font-weight: bold"><i class="fas fa-pencil-ruler icons"></i>{{
            $t("maintenance.materiales.title")
          }}</span>
          <span v-if="breadCrumb === 50" style="font-weight: bold"><i class="fas fa-list icons"></i>{{
            $t("maintenance.categories.title")
          }}</span>
          <span v-if="breadCrumb === 51" style="font-weight: bold"><i class="fas fa-chart-line icons"></i>{{
            $t("maintenance.seos.title")
          }}</span>
          <span v-if="breadCrumb === 25" style="font-weight: bold"><i class="fas fa-chalkboard-teacher icons"></i>{{
            $t("menu.mprofesor")
          }}</span>
          <span v-if="breadCrumb === 52" style="font-weight: bold"><i class="fas fa-envelope-open-text icons"></i>{{
            $t("contact.navbar")
          }}</span>

          <!-- Companies -->
          <span v-if="breadCrumb === 7" style="font-weight: bold">{{
            $t("login.titleEmp")
          }}</span>
          <span v-if="breadCrumb === 8" style="font-weight: bold"><i class="fas fa-users icons"></i>{{
            $t("menu.users")
          }}</span>
          <span v-if="breadCrumb === 9" style="font-weight: bold"><i class="fas fa-external-link-alt icons"></i>{{
            $t("mCompany.statistic.title")
          }}</span>
          <span v-if="breadCrumb === 10" style="font-weight: bold"><i class="fas fa-cogs icons"></i>{{
            $t("menu.config")
          }}</span>
          <span v-if="breadCrumb === 11" style="font-weight: bold"><i class="fas fa-graduation-cap icons"></i>{{
            $t("menu.compcourses")
          }}</span>
          <span v-if="breadCrumb === 12" style="font-weight: bold"><i class="fas fa-external-link-alt icons"></i>{{
            $t("menu.mylinks")
          }}</span>

          <!-- Users -->
          <span v-if="breadCrumb === 32" style="font-weight: bold"><i class="fas fa-folder-open icons"></i>{{
            $t("menu.courses")
          }}</span>
          <span v-if="breadCrumb === 13" style="font-weight: bold;font-size:14pt"><i class="fas fa-chalkboard icons"></i>{{
            titleCourse
          }}</span>
          <span v-if="breadCrumb === 14" style="font-weight: bold"><i class="fas fa-history icons"></i>{{
            $t("menu.chistory")
          }}</span>
          <span v-if="breadCrumb === 20" style="font-weight: bold">{{
            $t("login.titleUser")
          }}</span>

          <!-- Change password -->
          <span v-if="breadCrumb === 30" style="font-weight: bold">{{
            $t("menu.changep")
          }}</span>

          <!-- Profesor -->
          <span v-if="breadCrumb === 22" style="font-weight: bold">{{
            $t("profesor.login")
          }}</span>
          <span v-if="breadCrumb === 23" style="font-weight: bold"><i class="fas fa-user-tie icons"></i>{{
            $t("profesor.inicio")
          }}</span>
        </span>
      </div>

      <div class="d-flex">
        <span class="nav-item dropdown comp-navbar">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style="color: black"
          >
            <i class="fas fa-cog"></i>
          </a>
          <ul
            class="dropdown-menu dropdown-list"
            aria-labelledby="navbarDropdown"
          >
            <ChangeLanguage />
            <hr v-if="!allDrop" />
            <li v-if="!allDrop" style="margin-top: -4pt" @click="logout">
              <span class="dropdown dropicon"
                ><i class="fas fa-sign-out-alt"></i>
                {{ $t("actions.signout") }}</span
              >
            </li>
          </ul>
        </span>
        <img
          v-if="
            breadCrumb !== 13 &&
            breadCrumb !== 14 &&
            breadCrumb !== 20 &&
            breadCrumb !== 32 &&
            logo !== null
          "
          class="img-logo"
          :src="logo"
        />
        <!-- <img class="img-logo" src="../assets/img/logo-ad.png" /> -->
      </div>
    </div>
  </nav>
  <nav
    v-if="device"
    class="navbar navbar-light"
    style="background-color: white; width: 100%"
  >
    <div class="container-fluid" :style="breadCrumb === 13 ? 'display: block' : 'display: flex'">
      <div class="comp-navbar" :style="breadCrumb === 13 ? 'display: block; text-align: center;' : 'display: flex'">
        <img
          class="img-logo-main"
          v-if="!logo && breadCrumb !== 13"
          src="@/assets/img/logo-image.png"
          alt="logo"
        />
        <img class="img-logo-main" v-else-if="logo && breadCrumb !== 13" :src="logo" />
        <span style="font-size: 11pt">
          <!-- Super user -->
          <span v-if="breadCrumb === 1" style="font-weight: bold">{{
            $t("maintenance.companies.title")
          }}</span>
          <span v-if="breadCrumb === 2" style="font-weight: bold">{{
            $t("maintenance.countries.title")
          }}</span>
          <span v-if="breadCrumb === 3" style="font-weight: bold">{{
            $t("maintenance.languages.title")
          }}</span>
          <span v-if="breadCrumb === 15" style="font-weight: bold">{{
            $t("maintenance.courses.title")
          }}</span>
          <span v-if="breadCrumb === 16" style="font-weight: bold">{{
            $t("maintenance.themes.title")
          }}</span>
          <span v-if="breadCrumb === 17" style="font-weight: bold">{{
            $t("maintenance.blocks.title")
          }}</span>
          <span v-if="breadCrumb === 18" style="font-weight: bold">{{
            $t("maintenance.exams.title")
          }}</span>
          <span v-if="breadCrumb === 19" style="font-weight: bold">{{
            $t("maintenance.questions.title")
          }}</span>
          <span v-if="breadCrumb === 4" style="font-weight: bold">{{
            $t("maintenance.superusers.title")
          }}</span>
          <span v-if="breadCrumb === 5" style="font-weight: bold">{{
            $t("login.title")
          }}</span>
          <span v-if="breadCrumb === 6" style="font-weight: bold">{{
            $t("mCompany.statistic.title")
          }}</span>
          <span v-if="breadCrumb === 25" style="font-weight: bold">{{
            $t("menu.mprofesor")
          }}</span>
          <span v-if="breadCrumb === 21" style="font-weight: bold">{{
            $t("maintenance.materiales.title")
          }}</span>
          <span v-if="breadCrumb === 50" style="font-weight: bold">{{
            $t("maintenance.categories.title")
          }}</span>
          <span v-if="breadCrumb === 51" style="font-weight: bold">{{
            $t("maintenance.seos.title")
          }}</span>

          <!-- Companies -->
          <span v-if="breadCrumb === 7" style="font-weight: bold">{{
            $t("login.titleEmp")
          }}</span>
          <span v-if="breadCrumb === 8" style="font-weight: bold">{{
            $t("mCompany.users.title")
          }}</span>
          <span v-if="breadCrumb === 9" style="font-weight: bold">{{
            $t("mCompany.statistic.title")
          }}</span>
          <span v-if="breadCrumb === 10" style="font-weight: bold">{{
            $t("menu.config")
          }}</span>
          <span v-if="breadCrumb === 11" style="font-weight: bold">{{
            $t("menu.compcourses")
          }}</span>

          <!-- Users -->
          <span v-if="breadCrumb === 12" style="font-weight: bold">{{
            $t("menu.courses")
          }}</span>
          <span v-if="breadCrumb === 13" style="font-weight: bold">{{
            titleCourse
          }}</span>
          <span v-if="breadCrumb === 14" style="font-weight: bold">{{
            $t("menu.chistorymobile")
          }}</span>
          <span v-if="breadCrumb === 20" style="font-weight: bold">{{
            $t("login.titleUserMobile")
          }}</span>

          <!-- Change password -->
          <span v-if="breadCrumb === 30" style="font-weight: bold">{{
            $t("menu.changep")
          }}</span>
        </span>
      </div>
    </div>
  </nav>
</template>

<script>
import ChangeLanguage from "./ChangeLanguage";
import { mapState } from "vuex";

export default {
  props: ["device"],
  computed: mapState([
    "navbar",
    "allDrop",
    "breadCrumb",
    "id_emp",
    "logo",
    "servidor",
    "titleCourse",
    "comunica"
  ]),
  components: { ChangeLanguage },
  data() {
    return {
      emp_smtp: {},
    };
  },
  created() {
    this.verImagen();
  },
  methods: {
    verImagen() {
      // console.log(this.id_emp);
      if (this.id_emp) {
        this.logo = `${this.servidor}api/ver_logotipo.asp?emp_id=${this.id_emp}`;
      }
      // console.log(this.logo);
    },
    logout() {
      const path = this.$route.path;
      const logout = `${this.servidor}api/cerrar_sesion.asp`;
      // console.log(logout);
      if (path.includes("adminsu")) {
        this.$router.push("/adminsu/login");
      } else if (path.includes("admin")) {
        this.$router.push("/admin/login");
      } else if (path.includes("student")) {
        const idemp = path.split("/")[1];
        this.$router.push(`/${idemp}/student/login`);
      } else if (path.includes("profesor")) {
        this.$router.push(`/profesor/login`);
      }
    },
    configuration() {
      $("#configuration").modal("show");
    },
    sendConfiguration() {
      $("#configuration").modal("hide");
    },
  },
};
</script>

<style scoped>
.dropdown {
  font-weight: bold;
  padding: 10pt;
  font-size: 10pt;
}

.dropicon {
  margin-left: 5pt;
}

.dropdown-list {
  top: 35pt;
  left: -71pt;
}

li {
  cursor: pointer;
}

.comp-navbar {
  display: flex;
  align-items: center;
}

.img-logo {
  max-width: 280px;
  max-height: 80px;
}

i {
  font-size: 16pt;
}

.img-logo-main {
  max-width: 130pt;
  padding: 10pt;
}

.icons {
  font-size: 22pt;
  margin-right: 15px;
}
</style>