import { createRouter, createWebHistory } from 'vue-router'
import ChangePassword from '../views/CPassword.vue'
import axios from "axios";
import store from '../store/index.js'

const routes = [{
        path: '/adminsu/login',
        name: 'Login',
        meta: {
            isPublic: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/superuser/Login.vue')
    },
    {
        path: '/admin/login',
        name: 'LoginEmp',
        meta: {
            isPublic: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/company/Login.vue')
    },
    {
        path: '/:idemp/student/login',
        name: 'LoginUsu',
        meta: {
            isPublic: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/user/Login.vue')
    },
    {
        path: '/:idemp/student/restore',
        name: 'RestorePasswordUsu',
        meta: {
            isPublic: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/user/RestorePassword.vue')
    },
    {
        path: '/:idemp/changepassword/:code',
        name: 'CHANGEPASSWORD',
        meta: {
            isPublic: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: ChangePassword
    },
    // Superusers
    {
        path: "/adminsu/mteachers",
        name: "MTeacher",
        component: () =>
            import ('../views/superuser/mSuperuser/Teachers.vue')
    },
    {
        path: "/adminsu/mcontact",
        name: "MContact",
        component: () =>
            import ('../views/superuser/mSuperuser/Contact.vue')
    },
    {
        path: "/adminsu/mseo",
        name: "MSEO",
        component: () =>
            import ('../views/superuser/mSuperuser/SEO.vue')
    },
    {
        path: "/adminsu/mcategories",
        name: "MCategories",
        component: () =>
            import ('../views/superuser/mSuperuser/Categories.vue')
    },
    {
        path: "/adminsu/mblocks/:id",
        name: "MBlock",
        component: () =>
            import ('../views/superuser/mSuperuser/Blocks.vue')
    },
    {
        path: "/adminsu/mthemes/:id",
        name: "MTheme",
        component: () =>
            import ('../views/superuser/mSuperuser/Themes.vue')
    },
    {
        path: "/adminsu/mmaterial/:id",
        name: "MMaterial",
        component: () =>
            import ('../views/superuser/mSuperuser/Material.vue')
    },
    {
        path: "/adminsu/mexams",
        name: "MExam",
        component: () =>
            import ('../views/superuser/mSuperuser/Exams.vue')
    },
    {
        path: "/adminsu/mquestions/:id",
        name: "MQuestion",
        component: () =>
            import ('../views/superuser/mSuperuser/Questions.vue')
    },
    {
        path: "/adminsu/mcourse",
        name: "MCourse",
        component: () =>
            import ('../views/superuser/mSuperuser/Courses.vue')
    },
    {
        path: "/adminsu/mcourse/:id",
        name: "MACurso",
        component: () =>
            import ('../views/superuser/mSuperuser/Curso.vue')
    },
    {
        path: "/adminsu/mlanguage",
        name: "MLanguage",
        component: () =>
            import ('../views/superuser/mSuperuser/Languages.vue')
    },
    {
        path: "/adminsu/mcountry",
        name: "MCountry",
        component: () =>
            import ('../views/superuser/mSuperuser/Countries.vue')
    },
    {
        path: '/adminsu/ccompany',
        name: 'Company',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/superuser/mSuperuser/Companies.vue')
    },
    {
        path: '/adminsu/superuser',
        name: 'Superuser',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/superuser/mSuperuser/Superusers.vue')
    },
    // Companies
    {
        path: '/admin/student',
        name: 'Users',
        component: () =>
            import ('../views/company/mCompany/Users.vue')
    },
    {
        path: '/admin/configuration',
        name: 'CConfiguration',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/company/mCompany/Configuration.vue')
    },
    {
        path: "/admin/mcourse",
        name: "CCourse",
        component: () =>
            import ('../views/superuser/mSuperuser/Courses.vue')
    },
    {
        path: "/admin/mexams",
        name: "CExam",
        component: () =>
            import ('../views/superuser/mSuperuser/Exams.vue')
    },
    {
        path: "/admin/mblocks/:id",
        name: "CBlock",
        component: () =>
            import ('../views/superuser/mSuperuser/Blocks.vue')
    },
    {
        path: "/admin/mthemes/:id",
        name: "CTheme",
        component: () =>
            import ('../views/superuser/mSuperuser/Themes.vue')
    },
    {
        path: "/admin/mquestions/:id",
        name: "CQuestion",
        component: () =>
            import ('../views/superuser/mSuperuser/Questions.vue')
    },
    {
        path: "/admin/mteachers",
        name: "CTeacher",
        component: () =>
            import ('../views/superuser/mSuperuser/Teachers.vue')
    },
    {
        path: "/admin/mmaterial/:id",
        name: "CMaterial",
        component: () =>
            import ('../views/superuser/mSuperuser/Material.vue')
    },
    // Students
    {
        path: '/:idemp/student/mycourses',
        name: 'Mycourses',
        component: () =>
            import ('../views/user/mUser/Cursos.vue')
    },
    {
        path: '/:idemp/student/mycourse/:id',
        name: 'MyCourse',
        component: () =>
            import ('../views/user/mUser/Curso.vue')
    },
    {
        path: '/:idemp/student/myhistory',
        name: 'History',
        component: () =>
            import ('../views/user/mUser/Historial.vue')
    },
    {
        path: '/admin/mylinks',
        name: 'Mydata',
        component: () =>
            import ('../views/company/mCompany/Mylinks.vue')
    },
    {
        path: "/admin/mcourse/:id",
        name: "MCurso",
        component: () =>
            import ('../views/superuser/mSuperuser/Curso.vue')
    },
    // Profesor
    {
        path: '/profesor/login',
        name: 'LoginProfesor',
        meta: {
            isPublic: true
        },
        component: () =>
            import ('../views/profesor/Login.vue')
    },
    {
        path: '/profesor/inicio',
        name: 'InicioProfesor',
        component: () =>
            import ('../views/profesor/Inicio.vue')
    },
    {
        path: "/profesor/material/:id",
        name: "MPMaterial",
        component: () =>
            import ('../views/profesor/Material.vue')
    },
    {
        path: "/profesor/theme/:id",
        name: "MPTheme",
        component: () =>
            import ('../views/profesor/Themes.vue')
    },
    {
        path: "/profesor/course/:id",
        name: "MPCurso",
        component: () =>
            import ('../views/profesor/Curso.vue')
    },
    {
        path: "/profesor/blocks/:id",
        name: "MPBlock",
        component: () =>
            import ('../views/profesor/Blocks.vue')
    },
    {
        path: "/profesor/exams",
        name: "MPExam",
        component: () =>
            import ('../views/profesor/Exams.vue')
    },
    {
        path: "/profesor/questions/:id",
        name: "MPQuestion",
        component: () =>
            import ('../views/profesor/Questions.vue')
    },
    {
        path: "/profesor/wallet",
        name: "MPWallet",
        component: () =>
            import ('../views/profesor/Wallet.vue')
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(async(to, from, next) => {
    if (window.location.href.indexOf("consultoriacomunica") > 0) {
        store.commit('changeComunica', true);
        store.commit('changeServidor', 'https://formacion.consultoriacomunica.com/')
    } else {
        store.commit('changeComunica', false);
        store.commit('changeServidor', 'https://formacion.hieduca.com/')
    }

    let color = "";
    const params = to.fullPath.split('/');
    if (!to.matched.some(record => record.meta.isPublic)) {
        let res = ''
        if (to.path.includes('/adminsu')) {
            res = await axios.post(
                `${store.state.servidor}api/ident.asp?ac=comprobar`
            );
        } else if (to.path.includes('/admin')) {
            res = await axios.post(
                `${store.state.servidor}api/ident_empresa.asp?ac=comprobar`
            );
        } else if (to.path.includes('/student')) {
            res = await axios.post(
                `${store.state.servidor}api/ident_usuario.asp?ac=comprobar`
            );
        } else if (to.path.includes('/profesor')) {
            res = await axios.post(
                `${store.state.servidor}api/ident_profesor.asp?ac=comprobar`
            );
        }
        if (res && res.data.RESULT === '1') {
            if (to.path.includes('/adminsu')) {
                next('/adminsu/login');
            } else if (to.path.includes('/admin')) {
                next('/admin/login')
            } else if (to.path.includes('/profesor')) {
                next('/profesor/login')
            } else if (to.path.includes('/student')) {
                if (params.length > 3) {
                    next(`/${params[1]}/student/login`);
                } else {
                    next();
                }
            }
        } else {
            if (to.path.includes('/whistleblower')) {
                const colorRes = await axios.post(
                    `${store.state.servidor}api/op_denuncia.asp?tp=denunciante&ac=vercolor&id=${params[1]}`
                );
                color = colorRes.data.COLOR;
                const logo = `${store.state.servidor}api/ver_logotipo.asp?emp_id=${params[1]}`;
                store.commit('changeLogo', logo);
            } else if (to.path.includes('/adminsu')) {} else if (to.path.includes('/admin')) {
                const emp_id = res.data.EMP_ID;
                color = res.data.EMP_COLOR;
                store.state.id_emp = emp_id;
                const logo = `${store.state.servidor}api/ver_logotipo.asp?emp_id=${emp_id}`;
                store.commit('changeLogo', logo);
            } else if (to.path.includes('/student')) {
                const usu_rol = res.data.USU_ROL;
                const id_rol = res.data.USU_ID;
                store.state.usu_rol = usu_rol;
                store.state.id_usu = id_rol;
                const logo = `${store.state.servidor}api/ver_logotipo.asp?emp_id=${params[1]}`;
                store.commit('changeLogo', logo);
            }
            if (color === 0) {
                store.commit('changeColorCompanyOrange');
            } else if (color === 1) {
                store.commit('changeColorCompanyBlue');
            } else if (color === 2) {
                store.commit('changeColorCompanyGreen');
            } else if (color === 3) {
                store.commit('changeColorCompanyRed');
            }
            next();
        }
    } else {
        next();
    }
})

export default router