{
    "actions": {
        "clean": "Clean",
        "close": "Close",
        "send": "Send",
        "save": "Save",
        "singin": "Sign in",
        "toattach": "Documentation/Evidence",
        "restore": "Restore",
        "return": "Return",
        "confirm": "Accept",
        "cancel": "Cancel",
        "access": "Access",
        "create": "Create",
        "signout": "Log out",
        "accBlock": "Access the content",
        "btnProfesor": "Teacher contact",
        "sendMessage": "New message",
        "btnCertificate": "Download certificate"
    },
    "login": {
        "user": "User name",
        "password": "Password",
        "forgot": "Don't remember your password?",
        "title": "Superuser identification",
        "titleEmp": "Company identification",
        "titleUser": "User identification",
        "titleUserMobile": "Identification",
        "restore": "Restore",
        "titleRestore": "Restore password",
        "finalMesssage": "Your password has been recovered.",
        "restoreFail": "An error has occurred, please try again.",
        "email": "Email address",
        "error": "An error has occurred, please try again later",
        "errorUsu": "Username or password incorrect",
        "errorBlock": "User is locked out, you can contact an administrator to unlock it",
        "restoreFailEmail": "The email format is invalid"
    },
    "error": {
        "name": "The name is a required field",
        "user": "The user is a required field"
    },
    "menu": {
        "mcompany": "CREATE COMPANY",
        "msuperuser": "CREATE SUPERUSER",
        "countryMain": "COUNTRY MAINTENANCE",
        "mainLanguages": "LANGUAGE MAINTENANCE",
        "mainCourses": "COURSES MAINTENANCE",
        "mainExams": "EXAMS MAINTENANCE",
        "users": "USER MAINTENANCE",
        "mydata": "MY DATA",
        "statistics": "STATISTICS",
        "chistory": "HISTORY OF MY COURSES",
        "chistorymobile": "HISTORY",
        "courses": "MY COURSES",
        "course": "CURRENT course",
        "mylinks": "MY LINKS",
        "notice": "MY NOTICES",
        "noticemin": "My notices",
        "searcher": "SEARCHER",
        "config": "CONFIGURATION",
        "changep": "PASSWORD CHANGE",
        "compcourses": "courses",
        "vercurso": "VIEW COURSE",
        "vertemas": "VIEW THEMES",
        "verbloques": "VIEW BLOCKS",
        "vermaterial": "VIEW MATERIALS",
        "questions": "VIEW QUESTIONS",
        "editar": "EDIT",
        "borrar": "DELETE",
        "categories": "VIEW CATEGORIES",
        "seo": "VIEW SEO",
        "contact": "VIEW CONTACT",
        "mprofesor": "TEACHER MAINTENANCE",
        "vprofesor": "VIEW TEACHERS",
        "hecho": "FINISHED",
        "seecourses": "VIEW COURSES",
        "messages": "VIEW MESSAGES",
        "alert": "SEND ALERT",
        "deasigncourse": "REMOVE FROM COURSE",
        "asigncourse": "ASSIGN COURSE",
        "works": "VIEW WORKS"
    },
    "maintenance": {
        "actions": {
            "add": "Add",
            "cancel": "Cancelar",
            "confirm": "Accept"
        },
        "exams": {
            "question": "Questions",
            "title": "EXAM MAINTENANCE",
            "deleteTitle": "Deleting exam {name}",
            "deleteMessage": "Are you sure you want to delete the exam?",
            "deleteConfirmTitle": "The exam has been removed",
            "deleteConfirm": "The exam has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing exam {name}",
            "editMessage": "The exam has been edited",
            "addTitle": "Adding new exam"
        },
        "questions": {
            "question": "Question",
            "answer": "Answer",
            "canswer": "Correct answer",
            "title": "QUESTION MAINTENANCE",
            "deleteTitle": "Deleting question {name}",
            "deleteMessage": "Are you sure you want to delete the question?",
            "deleteConfirmTitle": "The question has been removed",
            "deleteConfirm": "The question has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing question {name}",
            "editMessage": "The question has been edited",
            "addTitle": "Adding new question"
        },
        "courses": {
            "title": "COURSE MAINTENANCE",
            "themes": "Themes",
            "students": "Students",
            "deleteTitle": "Deleting course {name}",
            "deleteMessage": "Are you sure you want to delete the course?",
            "deleteConfirmTitle": "The course has been removed",
            "deleteConfirm": "The course has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing course {name}",
            "editMessage": "The course has been edited",
            "addTitle": "Adding new course"
        },
        "themes": {
            "title": "THEME MAINTENANCE",
            "blocks": "Blocks",
            "deleteTitle": "Deleting content {name}",
            "deleteMessage": "Are you sure you want to delete the content?",
            "deleteConfirmTitle": "The content has been removed",
            "deleteConfirm": "The content has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing content {name}",
            "editMessage": "The content has been edited",
            "addTitle": "Adding new content"
        },
        "materiales": {
            "title": "MATERIAL MAINTENANCE",
            "deleteTitle": "Deleting material",
            "deleteMessage": "Are you sure you want to delete the material?",
            "deleteConfirmTitle": "The material has been removed",
            "deleteConfirm": "The material has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing material",
            "editMessage": "The material has been edited",
            "addTitle": "Adding new material"
        },
        "blocks": {
            "title": "CONTENT MAINTENANCE",
            "deleteTitle": "Deleting content {name}",
            "deleteMessage": "Are you sure you want to delete the content?",
            "deleteConfirmTitle": "The content has been removed",
            "deleteConfirm": "The content has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing content {name}",
            "editMessage": "The content has been edited",
            "addTitle": "Adding new content",
            "typeexam": "Exam type",
            "ruteYoutube": "Video url",
            "workStud": "Student work"
        },
        "roles": {
            "title": "ROLE MAINTENANCE",
            "name": "Name",
            "rol": "Role",
            "deleteTitle": "Deleting role {name}",
            "deleteMessage": "Are you sure you want to delete the role?",
            "deleteConfirmTitle": "The role has been removed",
            "deleteConfirm": "The role has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing role {name}",
            "editMessage": "The role has been edited"
        },
        "languages": {
            "title": "LANGUAGE MAINTENANCE",
            "name": "Name",
            "key": "Code",
            "deleteTitle": "Deleting language {name}",
            "deleteMessage": "Are you sure you want to delete the language?",
            "deleteConfirmTitle": "The language has been removed",
            "deleteConfirm": "The language has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing language {name}",
            "editMessage": "The language has been edited",
            "addTitle": "Adding new language"
        },
        "countries": {
            "title": "COUNTRY MAINTENANCE",
            "name": "Name",
            "id": "Code",
            "deleteTitle": "Deleting country {name}",
            "deleteMessage": "Are you sure you want to delete the country?",
            "deleteConfirmTitle": "The country has been removed",
            "deleteConfirm": "The country has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing country {name}",
            "editMessage": "The country has been edited",
            "addTitle": "Adding new country"
        },
        "categories": {
            "title": "CATEGORIES MAINTENANCE",
            "nameEsp": "Spanish name",
            "nameEng": "English name",
            "color": "Color",
            "deleteTitle": "Deleting category {name}",
            "deleteMessage": "Are you sure you want to delete the category?",
            "deleteConfirmTitle": "The category has been removed",
            "deleteConfirm": "The category has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing category {name}",
            "editMessage": "The category has been edited",
            "addTitle": "Adding new category"
        },
        "seos": {
            "title": "SEO MAINTENANCE",
            "titulo": "Title",
            "url": "URL",
            "description": "Description",
            "keywords": "Key words",
            "text": "Text",
            "lang": "Language",
            "deleteTitle": "Deleting seo {name}",
            "deleteMessage": "Are you sure you want to delete the seo?",
            "deleteConfirmTitle": "The seo has been removed",
            "deleteConfirm": "The seo has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing seo {name}",
            "editMessage": "The seo has been edited",
            "addTitle": "Adding new seo",
            "canonical": "Canonical"
        },
        "natures": {
            "title": "NATURE MAINTENANCE",
            "name": "Name",
            "id": "Code",
            "deleteTitle": "Deleting nature {name}",
            "deleteMessage": "Are you sure you want to delete the nature?",
            "deleteConfirmTitle": "The nature has been removed",
            "deleteConfirm": "The nature has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing nature {name}",
            "editMessage": "The nature has been edited",
            "addTitle": "Adding new nature"
        },
        "relations": {
            "title": "RELATION MAINTENANCE",
            "name": "Name",
            "deleteTitle": "Deleting relation {name}",
            "deleteMessage": "Are you sure you want to delete the relation?",
            "deleteConfirmTitle": "The relation has been removed",
            "deleteConfirm": "The relation has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing relation {name}",
            "editMessage": "The relation has been edited",
            "addTitle": "Adding new relation"
        },
        "superusers": {
            "title": "Super user",
            "name": "Name",
            "id": "Code",
            "email": "Email address",
            "password": "Password",
            "state": "State",
            "deleteTitle": "Deleting super user {name}",
            "deleteMessage": "Are you sure you want to delete the super user?",
            "deleteConfirmTitle": "The super user has been removed",
            "deleteConfirm": "The super user has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing super user {name}",
            "editMessage": "The super user has been edited",
            "addTitle": "Adding new super user",
            "lock": "Unlock"
        },
        "companies": {
            "title": "Companies",
            "name": "Name ",
            "cif": "CIF",
            "email": "Email address",
            "password": "Password",
            "direction": "Direction",
            "population": "Population",
            "cp": "CP",
            "province": "Province",
            "user": "User",
            "logo": "Logo",
            "designType": "Design type",
            "companyType": "Company type",
            "state": "State",
            "single": "Unique",
            "lang": "Language",
            "iGroup": "Is group?",
            "belongs": "Belongs",
            "country": "Country",
            "group": "What group does the company belong to?",
            "isGroup": "Does the company belong to a group?",
            "is-group": "Group?",
            "deleteTitle": "Deleting company {name}",
            "deleteMessage": "Are you sure you want to delete the company?",
            "deleteConfirmTitle": "The company has been removed",
            "deleteConfirm": "The company has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing company {name}",
            "editMessage": "The company has been edited",
            "addTitle": "Adding new company",
            "imgText": "Arrastre y suelte la imagen o pulse aquí",
            "unlock": "Unlock",
            "lock": "Lock",
            "typeRed": "Red type",
            "typeBlue": "Blue type",
            "typeGreen": "Green type",
            "typeOrange": "Orange type"
        },
        "fonts": {
            "title": "FONT MAINTENANCE",
            "name": "Name",
            "deleteTitle": "Deleting font {name}",
            "deleteMessage": "Are you sure you want to delete the font?",
            "deleteConfirmTitle": "The font has been removed",
            "deleteConfirm": "The font has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing font {name}",
            "editMessage": "The font has been edited",
            "addTitle": "Adding new font"
        }
    },
    "mCompany": {
        "users": {
            "title": "USER MAINTENANCE",
            "name": "Name",
            "password": "Password",
            "email": "Email",
            "dni": "DNI",
            "pertenece": "Belonging to",
            "user": "User",
            "user1": "User",
            "governingBody": "Governing body",
            "compliance": "Compliance officer",
            "rol": "Select a role",
            "rolTable": "Role",
            "deleteTitle": "Deleting user {name}",
            "deleteMessage": "Are you sure you want to delete the user?",
            "deleteConfirmTitle": "The user has been removed",
            "deleteConfirm": "The user has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing user {name}",
            "editMessage": "The user has been edited",
            "addTitle": "Adding new user",
            "state": "State",
            "signature": "Do you have a digital signature?",
            "courses": "Assigned courses",
            "listcourses": "Courses list",
            "assigned": "Courses assigned",
            "noAssigned": "Courses not assigned",
            "incourse": "In course",
            "finished": "Finshed",
            "theme": "Actual theme",
            "messages": "Messages with the student",
            "time": "Total time",
            "report": "REPORTS",
            "listMessages": "Messages",
            "rate": "RATE",
            "homework": "STUDENT WORK",
            "worksFromStudent": "Student work",
            "noworks": "The student has not uploaded works"
        },
        "departments": {
            "title": "DEPARTMENT MAINTENANCE",
            "name": "Name",
            "deleteTitle": "Deleting department {name}",
            "deleteMessage": "Are you sure you want to delete the department?",
            "deleteConfirmTitle": "The department has been removed",
            "deleteConfirm": "The department has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing department {name}",
            "editMessage": "The department has been edited",
            "addTitle": "Adding new department"
        },
        "headquarters": {
            "title": "HEADQUARTER MAINTENANCE",
            "name": "Name",
            "deleteTitle": "Deleting headquarter {name}",
            "deleteMessage": "Are you sure you want to delete the headquarter?",
            "deleteConfirmTitle": "The headquarter has been removed",
            "deleteConfirm": "The headquarter has been removed",
            "deleteError": "An error occurred while deleting",
            "editTitle": "Editing headquarter {name}",
            "editMessage": "The headquarter has been edited",
            "addTitle": "Adding new headquarter"
        },
        "mylink": {
            "title": "My links",
            "linkForms": "Link to forms",
            "linkUser": "Links to user login",
            "linkWhistleblower": "Link status of your consultation"
        },
        "statistic": {
            "title": "My statistics"
        }
    },
    "mUser": {
        "courses": {
            "title": "My courses",
            "titleProfesor": "My messages",
            "titleProfesor2": "Students",
            "titleProfesor3": "Course",
            "titleProfesor4": "Works",
            "name": "Title",
            "duration": "Duration",
            "lang": "Language",
            "description": "Description",
            "descriptionL": "Long description",
            "knowledge": "Previous knowledge",
            "theme": "Theme",
            "block": "Content",
            "calif": "Calification",
            "iWeb": "On the Web",
            "category": "Category",
            "price": "Price",
            "advice": "Advice",
            "iniDate": "Start date",
            "finDate": "Final date",
            "amount": "Number of students",
            "envioAviso": "Volver a enviar los datos de acceso",
            "outOfTime": "Not available: Out of time",
            "ncourse": "Course name",
            "nblock": "Content name",
            "web": "Web",
            "notaDelAlumno": "Student note"
        },
        "course": {
            "title": "Current course",
            "name": "Título",
            "duration": "Duración",
            "lang": "Idioma",
            "description": "Descripción",
            "block": "Content",
            "profesorTitle": "Mail for the teacher",
            "material": "COURSE MATERIAL",
            "mensaje": "Message",
            "workStudent": "The job has already been uploaded, you can only download it.",
            "problemProf": "If you need more information, contact the teacher.",
            "valCourse": "Rate course"
        },
        "history": {
            "title": "Course history",
            "name": "Título",
            "duration": "Duración",
            "lang": "Idioma",
            "description": "Descripción"
        }
    },
    "phases": {
        "actions": {
            "close": "Close Case",
            "save": "Save",
            "addFile": "Attach new file",
            "seeFiles": "View file history",
            "seePDF": "View PDF history"
        },
        "id": "ID",
        "date": "Date",
        "type": "Type",
        "state": "State",
        "lastUser": "Last user",
        "commentary": "Commentary"
    },
    "errors": {
        "0": "Data stored correctly",
        "1": "An error has occurred",
        "1.1": "Review the form data",
        "2": "Record already exists",
        "3": "There are no records",
        "4": "User unlocked",
        "8": "User blocked",
        "5": "Company unlocked",
        "6": "An error has occurred. Try it again later",
        "7": "You must select a certificate in order to close the case",
        "title": "Notice",
        "fieldOb": "The required fields must be filled (*)",
        "comment": "The fields with * are required",
        "errorEmail": "The email format is invalid"
    },
    "configuration": {
        "email": "Email",
        "user": "User",
        "password": "Password",
        "server": "Server",
        "port": "Port",
        "security": "Security",
        "nosec": "Without security",
        "footer": "GDPR legal footnote",
        "save": "Save configuration",
        "repeatPassword": "Repeat password",
        "messageError": "Passwords are different",
        "messageError2": "The password must be at least 8 characters long and contain an uppercase, lowercase, a number, and a symbol.",
        "change": "Record",
        "changePassword": "Password change",
        "newPassword": "New password"
    },
    "profesor": {
        "deleteTitle": "Deleting teacher {name}",
        "deleteMessage": "Are you sure you want to delete the teacher?",
        "deleteConfirmTitle": "The teacher has been removed",
        "deleteConfirm": "The teacher has been removed",
        "deleteError": "An error occurred while deleting",
        "editTitle": "Editing teacher {name}",
        "editMessage": "The teacher has been edited",
        "addTitle": "Adding new teacher",
        "login": "Teacher identification",
        "inicio": "MESSAGES WITH THE TEACHER",
        "titleAdd": "Send message...",
        "comisiones": "Wallet",
        "exams": "Exams",
        "courses": "My courses:",
        "solicitud": "REQUEST PUBLICATION",
        "name": "Name",
        "email": "Email",
        "password": "Password",
        "nac_date": "Birthday",
        "prefix": "Prefix",
        "phone": "Phone",
        "iban": "IBAN",
        "lang": "Language",
        "dni": "DNI",
        "revWork": "Work detail",
        "note": "Note",
        "wallet": {
            "commissions": "Commissions",
            "course": "Course",
            "price": "Price",
            "students": "Students completed",
            "total": "Total",
            "refunds": "Refund List",
            "date": "Date",
            "amount": "Amount",
            "state": "State",
            "totalBalance": "Total Balance",
            "reqRefund": "Request Refund"

        }
    },
    "languages": {
        "title": "Language: ",
        "es": "Spanish",
        "en": "English"
    },
    "contact": {
        "name": "Name",
        "email": "Email",
        "prefix": "Prefix",
        "phone": "Phone",
        "message": "Message",
        "lang": "Language",
        "ip": "Ip",
        "birthday": "Birthday",
        "dni": "DNI",
        "title": "Title",
        "type": "Type",
        "date": "Date",
        "state": "State",
        "navbar": "Contact maintenance",
        "viewTitle": "Contact details",
        "changeState": "Finalized"
    },
    "valor": {
        "puntos": "Rating",
        "comentario": "Comment",
        "title": "Course rating"
    }
}