<template>
  <div
    class="wrapper"
    style="font-family: 'Roboto', sans-serif"
    :class="{
      wrapper: !device,
      wrappermobile: device,
    }"
  >
    <navbar class="fixed-top" :device="device" />
    <change-language :device="true" />
    <router-view />
  </div>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
import Navbar from "./components/Navbar.vue";
import ChangeLanguage from "./components/ChangeLanguage.vue";

export default {
  components: {
    Navbar,
    ChangeLanguage,
  },
  data() {
    return {
      device: false,
    };
  },
  created() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      this.writeConsole("mobile device");
      this.device = true;
    } else {
      // false for not mobile device
      this.writeConsole("not mobile device");
      this.device = false;
    }    
    this.changeDevice(this.device);
  },
  methods: {
    ...mapMutations(["changeDevice","writeConsole"])
  },
};
</script>

<style>
html,
body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  /* background: linear-gradient(#eeeeee, #ffffff); */
  padding: 0;
  overflow: auto;
  /* font-size: 0.9rem; */
  background-color: #f2f0ee;
  font-size: calc(0.5em + 0.4vw);
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  /* background: linear-gradient(#eeeeee, #ffffff); */
}

.wrappermobile {
  display: block;
  width: 100%;
  align-items: stretch;
}

::placeholder {
  color: #898f96;
}
input {
  height: 35pt;
  text-indent: 20px;
  margin-bottom: 40px;
}

select {
  text-indent: 20px;
  text-transform: uppercase;
}

textarea {
  text-indent: 20px;
}

.label-radio {
  margin-top: 0.8pt;
}

.input-radio {
  margin-left: 17pt;
  margin-right: 6pt;
  margin-bottom: 20px;
}

.label-title-radio {
  padding-top: 10pt;
}

.radio-group {
  background-color: #f1f0ef;
  border-radius: 0.25rem;
}

.form-check-input:checked {
  background-color: #a4a9a8;
  border-color: #a4a9a8;
}

.table-header {
  margin-top: 100pt;
  margin-bottom: 20pt;
}

.tableheader {
  margin-top: 100pt;
  margin-bottom: 20pt;
}

.tableheadermobile {
  margin-top: 25pt;
  margin-bottom: 20pt;
}

.buttonCreate {
  background-color: #a4a9a8;
  border-radius: 50px;
  width: 95pt;
  font-weight: bold;
}

.buttonDelete {
  background-color: #a4a9a8;
  border-radius: 5px;
}

.buttonEdit {
  background-color: #a4a9a8;
  border-radius: 5px;
}

.buttonTrash {
  background-color: #a4a9a8;
  border-radius: 5px;
}

.btn {
  float: right;
}

.button {
  color: #fff;
}

#btnClean {
  border-bottom-left-radius: 0pt;
  border-top-left-radius: 0pt;
  width: 31%;
  background-color: #a4a9a8;
  /* margin-right: 24pt; */
  margin-left: 0px;
}

#btnSend {
  background-color: #e99417;
  border-bottom-right-radius: 0pt;
  border-top-right-radius: 0pt;
  width: 67%;
  margin-right: 0px;
}

#btnDelete {
  background-color: firebrick;
  border-bottom-right-radius: 0pt;
  border-top-right-radius: 0pt;
  width: 49%;
  margin-right: 0px;
}

.btnCrud {
  padding: 10px;
  width: 200pt;
  border-radius: 50px;
  color: #fff;
}

.input-state {
  width: 68%;
  display: initial;
}

.btn-state {
  width: 30%;
  height: 35pt;
}

.btn-return-result {
  width: 31%;
  background-color: #a4a9a8;
  border-radius: 25pt;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #333;
  color: #fff;
  font-size: 14px;
  position: absolute;
  padding: 10px 20px;
  bottom: 2em;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  border-radius: 6px;
}

[data-title] {
  position: relative;
}

.content {
  position: absolute;
  left: 160pt;
}

.modal-resp {
  width: 60%;
  max-width: none;
}

.modal-user {
  width: 40%;
  max-width: none;
}

.color-button-orange {
  background-color: #e99417;
}

.color-button-blue {
  background-color: #82dcce;
}

.color-button-green {
  background-color: #6ed966;
}

.color-button-red {
  background-color: #d53009;
}

@media screen and (max-width: 600px) {
  body {
    max-width: 100%;
    overflow-x: hidden;
  }  
}
</style>
